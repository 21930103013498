import React, { useContext } from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import GoogleMapReact from 'google-map-react';

import DestinationContext from '../contexts/DestinationContext';
import { cities, getClosestCity } from '../helpers';

const MOBILE_BREAKPOINT = '1000px';

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  font-style: normal;
  font-weight: normal;
  text-align: left;
  color: white;
  background-color: black;

  .brush-stroke {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-75px);
  }
`;

const TextContainer = styled.div`
  grid-column: 1;
  grid-row: 1;
  padding: 100px 120px 0;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    grid-column: 1 / 3;
    grid-row: 1;
    padding: 100px 30px 66px;
    text-align: center;
  }
`;

const MapContainer = styled.div`
  grid-column: 2;
  grid-row: 1 / 3;

  .gm-style .gm-fullscreen-control {
    top: 60px !important;
  }

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    grid-column: 1 / 3;
    grid-row: 2;
    height: 100vw;

    .gm-style .gm-fullscreen-control {
      top: 0 !important;
    }
  }
`;

const Title = styled.h2`
  margin-bottom: 40px;
  font-family: 'Gloss-And-Bloom', serif;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.2px;
`;

const Subtitle = styled.h3`
  margin-bottom: 40px;
  font-family: 'D-DINExp', serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.2px;
`;

const Description = styled.div`
  margin-bottom: 40px;
  font-family: 'D-DINExp';
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.3px;
`;

const BtnContainer = styled.div`
  grid-column: 1;
  grid-row: 2;
  margin-bottom: 66px;
  padding-left: 120px;
  text-align: left;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    grid-column: 1 / 3;
    grid-row: 3;
    margin-bottom: 0;
    padding: 40px 0 30px;
    text-align: center;
    background-color: white;
  }
`;

const markerPosition = {
  position: 'absolute',
  transform: 'translate(-50%, -100%)',
};

const Mark = () => (
  <svg
    width="42"
    height="42"
    xmlns="http://www.w3.org/2000/svg"
    style={markerPosition}
  >
    <g>
      <path
        stroke="null"
        id="svg_2"
        fill="#e74c3c"
        d="m21.536638,0.484914c-7.602285,0 -13.765086,6.162801 -13.765086,13.765086c0,2.445023 0.656595,4.731748 1.77432,6.720803c0.185657,0.330362 0.380261,0.655562 0.591555,0.968718l11.399212,19.840651l11.399212,-19.840651c0.175505,-0.259816 0.326921,-0.535118 0.483499,-0.806978l0.1084,-0.16174c1.116693,-1.989055 1.773975,-4.27578 1.773975,-6.720803c0,-7.602285 -6.163317,-13.765086 -13.765086,-13.765086zm0,6.882543c3.800884,0 6.882543,3.081487 6.882543,6.882543c0,3.800884 -3.081659,6.882543 -6.882543,6.882543c-3.801056,0 -6.882543,-3.081659 -6.882543,-6.882543c0,-3.801056 3.081487,-6.882543 6.882543,-6.882543z"
      />
      <path
        stroke="null"
        id="svg_3"
        fill="#c0392b"
        d="m21.482759,6.663794c-4.249223,0 -7.693965,3.458063 -7.693965,7.723717c0,4.265037 3.444742,7.723717 7.693965,7.723717c4.248608,0 7.693965,-3.45868 7.693965,-7.723717c0,-4.265654 -3.445358,-7.723717 -7.693965,-7.723717zm0,3.089487c2.54978,0 4.616379,2.074745 4.616379,4.63423s-2.066599,4.63423 -4.616379,4.63423s-4.616379,-2.074745 -4.616379,-4.63423s2.066599,-4.63423 4.616379,-4.63423z"
      />
    </g>
  </svg>
);

const GoogleMap = () => {
  const { cityName } = useContext(DestinationContext);
  const isBrowser = typeof window !== 'undefined';

  if (cityName.length && isBrowser) {
    window.sessionStorage.setItem('destination', cityName);
  }
  const currentLat = parseInt(
    isBrowser && window.sessionStorage.getItem('location-lat')
  );
  const currentLng = parseInt(
    isBrowser && window.sessionStorage.getItem('location-lng')
  );

  const getCoordinates = (position) => {
    if (isBrowser && window.sessionStorage.getItem('destination')) {
      return position === 'lat'
        ? cities[isBrowser && window.sessionStorage.getItem('destination')].lat
        : cities[isBrowser && window.sessionStorage.getItem('destination')].lng;
    }

    if (isBrowser && window.sessionStorage.getItem(`location-${position}`)) {
      return position === 'lat'
        ? cities[getClosestCity(currentLat, currentLng)].lat
        : cities[getClosestCity(currentLat, currentLng)].lng;
    }

    return position === 'lat' ? cities.Guelph.lat : cities.Guelph.lng;
  };

  const mapProps = {
    center: { lat: getCoordinates('lat'), lng: getCoordinates('lng') },
    zoom: 17,
  };
  const { center, zoom } = mapProps;

  const key = process.env.GATSBY_GOOGLEMAP_KEY;

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key }}
      defaultCenter={center}
      center={center}
      defaultZoom={zoom}
    >
      <Mark lat={getCoordinates('lat')} lng={getCoordinates('lng')} />
    </GoogleMapReact>
  );
};

const ProductTypeLocation = () => (
  <>
    <Container>
      <TextContainer>
        <Title>
          Providing Southern Ontario with the Best Cannabis Products
        </Title>
        <Subtitle>
          There's a Reason Ronin Cannabis is the Smart Choice for a 'Retail
          Store Near Me'
        </Subtitle>
        <Description>
          It's overwhelming trying to decide where you should buy your cannabis
          products, like cannabis drinks. There are hundreds of retail stores
          opening all across Ontario, and it can be impossible to know where to
          start.<br />
          <br />
          That's why Ronin Cannabis is committed to providing not only the best
          weed strains and weed accessories, but the best customer service as
          well. So, if you're looking for a new cannabis store
          in Guelph or weed delivery in Cambridge, Ronin Cannabis has everything
          you need. Our staff of expert budtenders are ready and eager to help
          you learn more about our cannabis products, so you can find the
          perfect cannabis consumption method to fit your tastes and
          preferences. <br />
          <br />
          Come see us today, and see why people are choosing Ronin Cannabis for
          their 'retail store near me'!
        </Description>
      </TextContainer>
      <MapContainer>
        <GoogleMap />
      </MapContainer>
      <BtnContainer>
        {/* <StyledLink primary >get direction</StyledLink> */}
      </BtnContainer>
      <StaticImage src="../../static/svg/BrushStroke.svg" alt="" className="brush-stroke" />
    </Container>
  </>
);

export default ProductTypeLocation;
