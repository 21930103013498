import React from 'react';
import styled from 'styled-components';

import ProductTypeCard from './ProductTypeCard';
import BlockContent from '@sanity/block-content-to-react';

const Container = styled.div`
  position: relative;
  z-index: 5;

  & > div {
    border-bottom: 1px solid #c4c4c4;

    &:last-child {
      border-bottom: none;
    }
  }

  .card-wrapper{
    position: relative;
    z-index: 6;
  }
`;


export default function ProductTypeSection({products}){
  return(
    <Container>
      <div className="card-wrapper">
      {products.map((item) => (
        <ProductTypeCard
          title={item.name}
          image={item.imagePlusAltHeader}
          descr={item._rawContent}
          slug={item.slug}
        />
      ))}
      </div>
    </Container>
  )
}
