import React, {useState} from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';

const Wrapper = styled.div`
    font-family: 'Space-Age';
`;

const ShopButtonStyle = styled.div`
    display:${({showButtons}) => (showButtons) ? 'none' : 'flex'};
    background: var(--pink);
    color: white;
    border: 0;
    padding: 0.6rem 1rem;
    cursor: pointer;
    box-shadow: 0px 0px 4px #E94799, 0px 0px 25px #E94799;
    border-radius: 3px;
    padding: 10px 60px;
    text-align: center;
    &:hover {
        color: var(--pink);
        background: white;
        box-shadow: 0px 0px 8px #E94799, 0px 0px 40px #E94799;
    }
    max-width: 300px;
    justify-content: center;
    align-items: center;
`;

const LocationWrapper = styled.div`
    display:${({showButtons}) => (showButtons) ? 'flex' : 'none'};
    @media (max-width: 1000px){
        flex-direction: column;
    }
`;

const LocationButton = styled.div`
    background: var(--pink);
    color: white;
    border: 0;
    padding: 0.6rem 1rem;
    cursor: pointer;
    box-shadow: 0px 0px 4px #E94799, 0px 0px 25px #E94799;
    border-radius: 3px;
    padding: 10px 60px;
    &:hover {
        color: var(--pink);
        background: white;
        box-shadow: 0px 0px 8px #E94799, 0px 0px 40px #E94799;
    }
    margin: 5px 5px;
`;





export default function ShopButton({slug}){
    const [showButtons, setShowButtons] = useState(false)

    return(
        <Wrapper>
            <ShopButtonStyle onClick={() => setShowButtons(true)} showButtons={showButtons}>Shop Now</ShopButtonStyle>
            <LocationWrapper showButtons={showButtons}>
                <LocationButton><Link to={`/guelph/livemenu${slug}`}> Guelph Menu</Link></LocationButton>
                <LocationButton><Link to={`/cambridge/livemenu${slug}`}>Cambridge Menu</Link></LocationButton>
            </LocationWrapper>
        </Wrapper>
    );
}