import React from 'react';
import StyledContainer from '../components/organisms/StyledContainer';
import ProductTypeCard from '../components/ProductTypeCard';
import ProductTypeHero from '../components/ProductTypeHero';
import ProductTypeLocation from '../components/ProductTypeLocation';
import { graphql } from 'gatsby';

import ProductTypeSection from '../components/ProductTypeSection';
import SEO from '../components/SEO';

export default function ProductTypePage({data}){
    return(
        <>
            <SEO
                title="Cannabis Beverages"
                description="Since its legalization in Canada, the volume of cannabis products being made available has 
                exploded into the market; paving the way for new types of cannabis consumption.
                This is shown perfectly in the example of cannabis beverages, which have quickly become a 
                popular way to enjoy cannabis without smoking or vaping it."
            />
            <ProductTypeHero
                title="Cannabis Beverages"
                subtitle="The New, Exciting Way to Enjoy Cannabis!"
                description={[
                    `Since its legalization in Canada, the volume of cannabis products being made available has 
                    exploded into the market; paving the way for new types of cannabis consumption.
                    This is shown perfectly in the example of cannabis beverages, which have quickly become a 
                    popular way to enjoy cannabis without smoking or vaping it. There are even a number of 
                    different types of cannabis drinks available, such as cannabis tea, carbonated cannabis drinks 
                    similar to sodas, and even weed water!`,
                ]}
                bannerImage={data.beveragesBackgroundImage}
            />
            <StyledContainer padding="120px">
                <ProductTypeSection products={data.bevy.nodes}/>
            </StyledContainer>
            <ProductTypeLocation/>
        </>
    );
}

export const query = graphql`
    query{
        bevy: allSanityProductType(filter: {productpage: {eq: "Beverages"}}) {
                nodes {
                    name
                    productpage
                    imagePlusAltHeader {
                        image {
                            asset {
                                gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, height: 250)
                            }
                        }
                        alt
                    }
                    _rawContent
                    slug
                }
        }

        beveragesBackgroundImage: file(relativePath: { eq: "beverages-bg.png" }) {
            childImageSharp {
              gatsbyImageData(
                width: 1000
                quality: 90
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
        }
    }
`;