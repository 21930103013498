import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { StaticImage, getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

const MOBILE_BREAKPOINT = '768px';

const Container = styled.div`
  position: relative;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  color: white;

  .hero-image,
  .hero-image::before,
  .hero-image::after {
    padding: 20vh 20px 0;
    height: 100vh;
  }

  .brush-stroke {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 140px;
    transform: translateY(105px);
  }

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    .hero-image,
    .hero-image::before,
    .hero-image::after {
      background-position-y: center;
      background-position-x: 45%;
      padding: 12vh 0;
      height: 80vh;
    }
  }
`;

const Title = styled.h1`
  margin-top: 40px;
  margin-bottom: 10px;
  font-family: 'Gloss-And-Bloom', serif;
  font-size: 48px;
  line-height: 120%;
  letter-spacing: 0.2px;
  text-shadow: -1px 1px 0 #000,
                          1px 1px 0 #000,
                         1px -1px 0 #000,
                        -1px -1px 0 #000;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    font-size: 32px;
  }
`;

const Subtitle = styled.h3`
  margin-bottom: 30px;
  font-family: 'D-DINExp', serif;
  font-weight: bold;
  line-height: 140%;
  letter-spacing: 0.2px;
  text-shadow: -1px 1px 0 #000,
                          1px 1px 0 #000,
                         1px -1px 0 #000,
                        -1px -1px 0 #000;
`;

const Description = styled.div`
  margin-bottom: 30px;
  padding: 0 25%;
  font-family: 'D-DINExp', serif;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.3px;
  text-shadow: -1px 1px 0 #000,
                          1px 1px 0 #000,
                         1px -1px 0 #000,
                        -1px -1px 0 #000;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    padding: 0;
  }
`;


const ProductTypeHero = ({title, subtitle, description, bannerImage}) => {

  const image = getImage(bannerImage);
  const bgImage = convertToBgImage(image);

  return (
    <Container>
      <BackgroundImage
        Tag="div"
        {...bgImage}
        preserveStackingContext
        className="hero-image"
        style={{
          width: '100%',
          height: '',
          backgroundPosition: ''
        }}
      >
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <Description>
          {description.map(paragraph => (
              <p>{paragraph}</p>
          ))}
        </Description>
        <button ><Link to="/locations">Shop Online</Link></button>
      </BackgroundImage>
      <StaticImage src='../../static/svg/BrushStroke.svg' alt="" className="brush-stroke"/>
    </Container>
  );
};

export default ProductTypeHero;
