import React from 'react';
import styled from 'styled-components';

import StyledLink from './organisms/StyledLink';
import BlockContent from '@sanity/block-content-to-react';
import {getGatsbyImageData} from 'gatsby-source-sanity';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import clientConfig from "../../client-config";
import ShopButton from './ShopNowButton';

const MOBILE_BREAKPOINT = '1000px';

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: center;
    padding: 30px 10px;
`;

const Title = styled.h3`
  grid-column: 2;
  grid-row: 1;
  margin-bottom: 20px;
  font-family: 'Gloss-And-Bloom', serif;
  font-size: 36px;
  font-weight: normal;
  line-height: 120%;
  letter-spacing: 0.2px;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    grid-column: 1 / 3;
    grid-row: 1;
    font-size: 32px;
    line-height: 150%;
    text-align: center;
  }
`;

const ImageContainer = styled.div`
    grid-column: 1;
    grid-row: 1 / 3;
    display: flex;
    justify-content: center;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    grid-column: 1 / 3;
    grid-row: 2;
  }
`;

const TextContainer = styled.div`
  grid-column: 2;
  grid-row: 2;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    grid-column: 1 / 3;
    grid-row: 3;
    text-align: center;
  }
`;

const Description = styled.div`
  margin-bottom: 30px;
  font-family: 'D-DINExp', serif;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.3px;
  color: #6d6e71;
`;

export default function ProductTypeCard({image, descr, title, slug}){
    // const gatsbyImageData = getGatsbyImageData(
    //     image.image.asset,
    //     clientConfig.sanity
    // )
    // const readyImage = getImage(gatsbyImageData);
    return(
        <Container>
            <Title>{title}</Title>
            <ImageContainer>
                <GatsbyImage 
                    image={image.image.asset.gatsbyImageData} 
                    alt={image.alt}
                    height={300}
                />
            </ImageContainer>
            <TextContainer>
                <Description>
                    <BlockContent blocks={descr} />
                </Description>
                <ShopButton slug={slug}/>
            </TextContainer>
        </Container>
    )
}
